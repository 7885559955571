
import "../../components/home/home.css";
import { Link, useNavigate } from "react-router-dom";
import HomeImg from "../../assets/Images/Rectangle310.png";
import newsImg from "../../assets/Images/image126.jpg";
import Doctor1 from "../../assets/Images/Group7261.png";
import Doctor2 from "../../assets/Images/Group7262.png";
import Medicine from "../../assets/Images/Group7267.png";
import Medicine5 from "../../assets/Images/Group7268.png";
import Medicine7 from "../../assets/Images/Group7270.png";
import Medicin4 from "../../assets/Images/Group7267.png";
import Medicine2 from "../../assets/Images/Group7267.png";
import Medicine3 from "../../assets/Images/Group7267.png";
import Medicine4 from "../../assets/Images/Group7267.png";
import Image174 from "../../assets/Images/image174.jpg";
import gorup7272 from "../../assets/Images/Group7272.png";
import gorup7271 from "../../assets/Images/Group7271.png";
import Image168 from "../../assets/Images/image168.jpg";
import oilImage from "../../assets/Images/image189.png";
import tabletImage from "../../assets/Images/image185.png";
import moveImage from "../../assets/Images/image183.png";
import Group3148 from "../../assets/Images/Group3148.png";
import Group3149 from "../../assets/Images/Group3149.png";
import Group3150 from "../../assets/Images/Group3150.png";
import Group3151 from "../../assets/Images/Group3151.png";
import Group3152 from "../../assets/Images/Group3152.png";
import Group3153 from "../../assets/Images/Group3153.png";
import Group3154 from "../../assets/Images/Group3154.png";
import Group3155 from "../../assets/Images/Group3155.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";

import { Pagination, Autoplay } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { resetAllCardData } from "../../Reducers/allSubcategorySlice";

import { RootState } from "../../store";
import { useEffect, useState } from "react";
import { newsData } from "../../Reducers/newsSlice";
import { detailData } from "../../Reducers/detailsSlice";
import { Loader } from "../loader/Loader";
import { Height } from "@mui/icons-material";

import { regulatoryData } from "../../Reducers/regulatorySlice";
import { fetchRegulatorySubDataMobile } from "../../Reducers/allSubcategorySlice";
import { gmpTrainingData } from '../../Reducers/gmpTrainingSlice';
import { fetchGmpSubDataMobile } from '../../Reducers/allSubcategorySlice';
import { businessData } from "../../Reducers/businessSlice";
import { fetchBusinessSubDataMobile } from "../../Reducers/allSubcategorySlice";
import { productData } from '../../Reducers/productSlice';
import { fetchProductSubData } from '../../Reducers/cardSlice';
import { setMobileHeadingData } from "../../Reducers/mobileHeadingSlice";


const Home = () => {




  const swiperParams2 = {
    slidesPerView: 1.5,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    loop: true,
    spaceBetween: 25,
    breakpoints: {
      480: {
        slidesPerView: 3,
        spaceBetween: -2,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: -2,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: -2,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: -2,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      1400: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  };


  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { NewsData, status, error } = useSelector(
    (state: RootState) => state.news
  );
  const filterNewsData = NewsData.slice(1, 7);
  const FirstNewsData = NewsData.slice(0, 1);

  const handleCardClick = (id: string) => {
    const selectedNews = NewsData;
    dispatch(detailData({ id }));
    navigate(`/details/${id}`, { state: { selectedNews } });
  };

  const handleCardClick2 = (url: string) => {
    window.open(url, '_blank');
  };
  useEffect(() => {
    setLoading(true);
    dispatch(setMobileHeadingData("Home"))
    dispatch(resetAllCardData());
    dispatch(newsData())
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [dispatch]);



  // regulatory 

  const regulatoryDispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { RegulatoryData } = useSelector(
    (state: RootState) => state.regulatory
  );
  const filterRegulatoryData =  RegulatoryData.slice(0,4)
  useEffect(() => {
    regulatoryDispatch(regulatoryData())
      .then(() => {
        setLoading(false);

      }).catch((error) => {
        setLoading(false);

      })
  }, [regulatoryDispatch]);


  // GMP training


  const gmptrainingDispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { GmpTrainingData } = useSelector((state: RootState) => state.gmptraining);
  const filterGmpTrainingData = GmpTrainingData.slice(0, 4)
  useEffect(() => {
    gmptrainingDispatch(gmpTrainingData())
      .then(() => {
        setLoading(false);
      }).catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      })
  }, [gmptrainingDispatch]);


  // Business


  const businessDispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { BusinessData } = useSelector(
    (state: RootState) => state.business
  );
  const filterBusinessData = BusinessData.slice(1, 5);
  const firstBusinessData = BusinessData.slice(0, 1);
  useEffect(() => {
    setLoading(true);
    businessDispatch(businessData())
      .then(() => {
        setLoading(false);
      }).catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      })
  }, [businessDispatch]);


  //product

  const productDispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { ProductData } = useSelector((state: RootState) => state.product);
  const filterProductData = ProductData.slice(0, 4)
  useEffect(() => {
    productDispatch(fetchProductSubData())
    productDispatch(productData());
  }, [productDispatch])


  return (
    <>
      {loading && <Loader />}
      <div className="container backgroundblur">
        <div className="row mb-3 mt-4 g-3 custom-margin-home">
          <div className="col-lg-6 col-md-8 col-12 px-2 All-col">
            <div className="card-image-news"
              onClick={() => {
                if (FirstNewsData[0]?.name === "Internal News") {
                  handleCardClick(FirstNewsData[0]?._id);
                } else {
                  handleCardClick2(FirstNewsData[0]?.link);
                }
              }}>
              <img
                src={`${process.env.REACT_APP_API_URL}/${FirstNewsData[0]?.news_image[0]?.src}`}
                className="img-fluid news-card-image"
                alt="Home"
              />
              <div className="main_content_for_absoulte-news">
                {FirstNewsData[0]?.logo_image.length > 0 || FirstNewsData[0]?.logo_title ?
                  <div className="for_img_text">
                    {FirstNewsData[0]?.logo_image.length > 0 ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${FirstNewsData[0]?.logo_image[0]?.src}`}
                        className="img-fluid leftImg"
                        alt="News"
                      />
                    ) : (
                      ""
                    )}

                    <span
                      className="text-light for_img-text_for_mobile"
                      style={{ fontWeight: "400", fontSize: "16px" }}
                    >
                      {FirstNewsData[0]?.logo_title && FirstNewsData[0]?.logo_title.split(" ").slice(0, 2).join(" ").toUpperCase()}
                    </span>
                  </div> : ""}

                <div className="lorem-text-news">
                  <p
                    className="text-light top-image-text-news"
                    style={{ fontWeight: "500", fontSize: "18px", lineHeight: "20px" }}
                  >
                    {FirstNewsData[0]?.title}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {filterNewsData?.map((data: any, index: number) => (
            data.status === "active" ? (
              <div className="col-lg-3 col-sm-6 col-md-4 All-col myheight" key={data._id}>
                <div
                  className="card custom-border-shadow-News"
                  style={{ cursor: "pointer", height: "100%" }}
                  onClick={() => {
                    if (data.name === "Internal News") {
                      handleCardClick(data._id);
                    } else {
                      handleCardClick2(data.link);
                    }
                  }}
                >
                  <div className="custom-img-parent-News card-box">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${data.news_image[0]?.src}`}
                      className="card-img-top-news"
                      alt="hiiiii"
                    />
                    <div className="image-lineae-gradient"></div>
                    <div className="img-absolute-News">
                      <div>
                        {data.logo_image.length > 0 ? (
                          <img
                            src={`${process.env.REACT_APP_API_URL}/${data.logo_image[0]?.src}`}
                            alt="hii"
                            className="srcin leftImg"
                          />
                        ) : null}
                      </div>

                      <div className="newschanel">
                        <small className="text-light custom-mobile-view-news">
                          {data.logo_title && data.logo_title.split(" ").slice(0, 2).join(" ").toUpperCase()}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="custom-card-body-news">
                    <p className="card-text for-custom-card-text-news card_content-news">
                      {data.title}
                    </p>
                  </div>
                </div>
              </div>
            ) : null
          ))}
        </div>

{/* 

        <div className="row mb-3 mt-4 g-3 custom-margin-home hide-on-mobile">
  <Swiper
    {...swiperParams2}
    modules={[Pagination, Autoplay]}
    className="custom-swiper mt-3 responsive-swiper"
  >
    {filterRegulatoryData?.map((data: any, index: number) => (
      <SwiperSlide className="custom-swiper-slide" key={data._id}>
        {data.status === "active" ? (
          <div
            className="card custom-border-shadow-News"
            style={{ cursor: "pointer", height: "100%" }}
            onClick={() => {
              if (data.name === " Regulatory data") {
                handleCardClick(data._id);
              } else {
                handleCardClick2(data.link);
              }
            }}
          >
            <div className="custom-card-img" style={{ position: 'relative' }}>
              <img
                src={`${process.env.REACT_APP_API_URL}/${data.news_image[0]?.src}`}
                className="img-fluid custom-medicine-img"
                style={{ height: "380px", objectFit: "cover", width: "100%", borderRadius: '10px' }}
                alt="News"
              />
              <div className="custom-overlay-content">
                <img
                  src={Image174}
                  className="img-fluid custom-logo-img"
                  alt="Logo"
                />
                <span className="custom-logo-title text-light">
                  {data.logo_title &&
                    data.logo_title.split(' ').slice(0, 2).join(' ').toUpperCase()}
                </span>
                <p className="custom-card-paragraph text-light">
                  {data.title &&
                    data.title.split(' ').slice(0, 13).join(' ') + "..."}
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </SwiperSlide>
    ))}
  </Swiper>
</div> */}


<div className="row mb-3 mt-4 g-3 custom-margin-home">
  <Swiper
    {...swiperParams2}
    modules={[Pagination, Autoplay]}
    className="mySwiper mt-3 for_mobile_viewswiper"
  >
    {RegulatoryData?.map((data: any, index: number) => (
      data.status === "active" ? (
        <SwiperSlide className="swiper-m-right" key={data._id}>
          <div
            className="card-imges"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (data.name === "Internal Regulatory") {
                handleCardClick(data._id);
              }
              else {
                handleCardClick2(data.link)
              }
            }}>
            <img 
              src={`${process.env.REACT_APP_API_URL}/${data.news_image[0]?.src}`} 
              className="img-fluid for-Medicine-img-home" 
              style={{ height: "380px" }} 
              alt="..." 
            />
          </div>
          <div className="news card-para-content2">
            <div className="image-lineae-gradient"></div>
            <div className="content-container">
              <div className="card-text2">
                <img
                  src={Image174}
                  className="img-fluid for-card-para-img" 
                />
                <span className="text-light">
                  {data.logo_title && data.logo_title.split(' ').slice(0, 2).join(' ').toUpperCase()}
                </span>
              </div>
              <p
                className="text-light for-mobile-view card_content"
                style={{ fontWeight: "500" }}
              >
                {data.title && data.title.split(' ').slice(0, 13).join(' ') + "..."}
              </p>
            </div>
          </div>
        </SwiperSlide>
      ) : null
    ))}
  </Swiper>
</div>





        <div className="row g-3 mt-2">
          {filterGmpTrainingData?.map((data: any, index: number) => (
            data.status === "active" ? (
              <div className="col-lg-3 col-sm-6 col-md-4 All-col myheight" key={data._id}>
                <div
                  className="card custom-border-shadow-News"
                  style={{ cursor: "pointer", height: "100%" }}
                  onClick={() => {
                    if (data.name === "Internal GMP") {
                      handleCardClick(data._id);
                    } else {
                      handleCardClick2(data.link);
                    }
                  }}
                >
                  <div className="custom-img-parent-News card-box">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${data.news_image[0]?.src}`}
                      className="card-img-top-news"
                      alt="..."
                    />
                    <div className="image-lineae-gradient"></div>
                    <div className="img-absolute-News">
                      <div>
                        {data.logo_image.length > 0 ? (
                          <img src={`${process.env.REACT_APP_API_URL}/${data.logo_image[0]?.src}`} alt="" className="srcin leftImg" />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="newschanel">
                        <small className="text-light custom-mobile-view-news">
                          {data.logo_title && data.logo_title.split(" ").slice(0, 2).join(" ").toUpperCase()}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="custom-card-body-news">
                    <p className="card-text for-custom-card-text-news card_content-news">
                      {data.title}
                    </p>
                  </div>
                </div>
              </div>
            ) : null
          ))}
        </div>

       
       
       
        <div className="hide-on-small-screens">
  {firstBusinessData && firstBusinessData.length > 0 ? (
    <div className="row g-3 mt-2">
      <div className="col-lg-4 col-md-4 col-12 pl-2 custom-cards-gap card-gap">
        <div
          className="for-info-section custom-class-for-medicine-section row-span-2"
          onClick={() => {
            if (firstBusinessData[0]?.name === "Internal Business") {
              handleCardClick(firstBusinessData[0]?._id);
            } else {
              handleCardClick2(firstBusinessData[0]?.link);
            }
          }}
        >
          <img
            src={`${process.env.REACT_APP_API_URL}/${firstBusinessData[0]?.news_image[0]?.src}`}
            className="img-fluid for-Medicine-img for-Medicine-img-1"
          />
          <div className="cont-on-img">
            <div className="card-img-para">
              <p className="text-light card_content">
                {firstBusinessData[0]?.title.split(' ').slice(0, 13).join(' ') + "..."}
              </p>
              <div className="card-text4">
                {firstBusinessData[0]?.logo_image.length > 0 ? (
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${firstBusinessData[0]?.logo_image[0]?.src}`}
                    className="img-fluid for-card-para-img"
                  />
                ) : (
                  ""
                )}
                <span className="font-color-text-white">
                  {firstBusinessData[0]?.logo_title &&
                    firstBusinessData[0]?.logo_title.split(' ').slice(0, 2).join(' ').toUpperCase()}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-8 col-md-8 col-12 pl-2 custom-cards-gap">
        <div className="row">
          {filterBusinessData?.map((data: any, index: number) =>
            data.status === "active" ? (
              <div className="col-lg-6 col-md-6 card-gap" key={data._id}>
                <div
                  className="for-info-section"
                  onClick={() => {
                    if (data.name === "Internal Business") {
                      handleCardClick(data._id);
                    } else {
                      handleCardClick2(data.link);
                    }
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${data.news_image[0]?.src}`}
                    className="img-fluid for-Medicine-img"
                  />
                  <div className="cont-on-img">
                    <div className="card-img-para">
                      <p className="text-light card_content">
                        {data.title &&
                          data.title.split(" ").slice(0, 13).join(" ") + "..."}
                      </p>
                      <div className="card-text4">
                        {data.logo_image.length > 0 ? (
                          <img
                            src={`${process.env.REACT_APP_API_URL}/${data.logo_image[0]?.src}`}
                            alt=""
                            className="srcin leftImg"
                          />
                        ) : (
                          ""
                        )}
                        <span className="font-color-text-white">
                          {data.logo_title &&
                            data.logo_title
                              .split(" ")
                              .slice(0, 2)
                              .join(" ")
                              .toUpperCase()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="row g-3 mt-2">
      <div className="col-lg-4 col-md-4 col-12 pl-2 custom-cards-gap card-gap">
        <div className="for-info-section custom-class-for-medicine-section row-span-2">
          <div className="cont-on-img">
            <div className="card-img-para">
              <p className="text-light card_content"></p>
              <div className="card-text4">
                <span className="font-color-text-white"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )}
</div>







        <div className="row g-3 mt-2 custom-cards-gap">
          {filterProductData?.map((data: any, index: number) => (
            data.status === "active" ? (
              <div className="col-lg-3 col-sm-6 col-md-4 All-cols myheight" key={data._id}>
                <div
                  className="card custom-border-shadow-News"
                  style={{ cursor: "pointer", height: "100%" }}
                  onClick={() => {
                    if (data.name === "Internal Product") {
                      handleCardClick(data._id);
                    } else {
                      handleCardClick2(data.link);
                    }
                  }}
                >
                  <div className="custom-img-parent-News card-box">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${data.news_image[0]?.src}`}
                      className="card-img-top-news"
                      alt="..."
                    />
                    <div className="image-lineae-gradient"></div>
                    <div className="img-absolute-News">
                      <div>
                        {data.logo_image.length > 0 ? (
                          <img src={`${process.env.REACT_APP_API_URL}/${data.logo_image[0]?.src}`} alt="" className="srcin leftImg" />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="newschanel">
                        <small className="text-light custom-mobile-view-news">
                          {data.logo_title && data.logo_title.split(" ").slice(0, 2).join(" ").toUpperCase()}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="custom-card-body-news">
                    <p className="card-text for-custom-card-text-news card_content-news">
                      {data.title}
                    </p>
                  </div>
                </div>
              </div>
            ) : null
          ))}

          {/* 
          <div className="col-lg-3 col-sm-12 col-md-3 custom-cards-gap">
            <div
              className="card custom-border-shadow-News"
              style={{ cursor: "pointer" }}
            >
              <div className="custom-img-parent-News">
                <img src={Doctor2} className="card-img-top" alt="..." />
                <div className="image-lineae-gradient"></div>
                <div className="img-absolute-News">
                  <div className="">
                    <img src={newsImg} alt="" className="src leftImg" />
                  </div>
                  <div className="newschanel">
                    {" "}
                    <small className="text-light custom-mobile-view-news">
                      NDTV
                    </small>
                  </div>
                </div>
              </div>
              <div className="card-body custom-card-body-news">
                <p className="card-text for-custom-card-text-news card_content">

                  Lorem Ipsum is simply dummy text of the printing the printing super natural thing it is nice...
                </p>
              </div>
            </div>
          </div>
           */}
          {/* <div className="col-lg-3 col-sm-12 col-md-3 custom-cards-gap">
            <div
              className="card custom-border-shadow-News"
              style={{ cursor: "pointer" }}
            >
              <div className="custom-img-parent-News">
                <img src={Doctor2} className="card-img-top" alt="..." />
                <div className="image-lineae-gradient"></div>
                <div className="img-absolute-News">
                  <div className="">
                    <img src={newsImg} alt="" className="src leftImg" />
                  </div>
                  <div className="newschanel">
                    {" "}
                    <small className="text-light custom-mobile-view-news">
                      NDTV
                    </small>
                  </div>
                </div>
              </div>
              <div className="card-body custom-card-body-news">
                <p className="card-text for-custom-card-text-news card_content">

                  Lorem Ipsum is simply dummy text of the printing the printing super natural thing it is nice...
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-12 col-md-3 custom-cards-gap">
            <div
              className="card custom-border-shadow-News"
              style={{ cursor: "pointer" }}
            >
              <div className="custom-img-parent-News">
                <img src={Doctor2} className="card-img-top" alt="..." />
                <div className="image-lineae-gradient"></div>
                <div className="img-absolute-News">
                  <div className="">
                    <img src={newsImg} alt="" className="src leftImg" />
                  </div>
                  <div className="newschanel">
                    {" "}
                    <small className="text-light custom-mobile-view-news">
                      NDTV
                    </small>
                  </div>
                </div>
              </div>
              <div className="card-body custom-card-body-news">
                <p className="card-text for-custom-card-text-news card_content">

                  Lorem Ipsum is simply dummy text of the printing the printing super natural thing it is nice...
                </p>
              </div>
            </div>
          </div>*/}
        </div>

        {/* <div className="row g-3 mt-2">
          <div className="col-lg-4 col-md-4 col-12 pl-2 ">
            <div className="for-info-section custom-class-for-medicine-section">
              <img src={gorup7271} className="img-fluid for-Medicine-img" />
              <div className="cont-on-img">
                <p className="text-light">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry...
                </p>
                <div className="card-text4">
                  <img src={Image168} className="img-fluid for-card-para-img" />
                  <span className="font-color-text-white">ZEE NEWS</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-md-8 col-12 pl-2">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="for-info-section">
                  <img src={gorup7272} className="img-fluid for-Medicine-img" />
                  <div className="cont-on-img">
                    <div className="card-img-para">
                      <p className="text-light card_content">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry...
                      </p>
                      <div className="card-text4">
                        <img
                          src={Image168}
                          className="img-fluid for-card-para-img"
                        />
                        <span className="font-color-text-white">ZEE NEWS</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 pl-2">
                <div className="for-info-section custom-class-for-medicine-section">
                  <img src={gorup7272} className="img-fluid for-Medicine-img" />
                  <div className="cont-on-img">
                    <div className="card-img-para">
                      <p className="text-light card_content">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry...
                      </p>
                      <div className="card-text4">
                        <img src={Image168} />
                        <span className="font-color-text-white">ZEE NEWS</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mt-3">
                <div className="for-info-section">
                  <img src={gorup7272} className="img-fluid for-Medicine-img" />
                  <div className="cont-on-img">
                    <div className="card-img-para">
                      <p className="text-light card_content">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry...
                      </p>
                      <div className="card-text4">
                        <img
                          src={Image168}
                          className="img-fluid for-card-para-img"
                        />
                        <span className="font-color-text-white">ZEE NEWS</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mt-3">
                <div className="for-info-section">
                  <img src={gorup7272} className="img-fluid for-Medicine-img" />
                  <div className="cont-on-img">
                    <div className="card-img-para">
                      <p className="text-light card_content">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry...
                      </p>
                      <div className="card-text4">
                        <img
                          src={Image168}
                          className="img-fluid for-card-para-img"
                        />
                        <span className="font-color-text-white">ZEE NEWS</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-3 mt-2">
          <div className="col-lg-3 col-sm-12 col-md-3">
            <div
              className="card custom-border-shadow-News"
              style={{ cursor: "pointer" }}
            >
              <div className="custom-img-parent-News">
                <img src={Doctor2} className="card-img-top" alt="..." />
                <div className="image-lineae-gradient"></div>
                <div className="img-absolute-News">
                  <div className="">
                    <img src={newsImg} alt="" className="src leftImg" />
                  </div>
                  <div className="newschanel">
                    {" "}
                    <small className="text-light">NDTV</small>
                  </div>
                </div>
              </div>
              <div className="card-body custom-card-body-news">
                <p className="card-text for-custom-card-text-news card_content">

                  Lorem Ipsum is simply dummy text of the printing the printing super natural thing it is nice...
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-12 col-md-3">
            <div
              className="card custom-border-shadow-News"
              style={{ cursor: "pointer" }}
            >
              <div className="custom-img-parent-News">
                <img src={Doctor2} className="card-img-top" alt="..." />
                <div className="image-lineae-gradient"></div>
                <div className="img-absolute-News">
                  <div className="">
                    <img src={newsImg} alt="" className="src leftImg" />
                  </div>
                  <div className="newschanel">
                    {" "}
                    <small className="text-light custom-mobile-view-news">
                      NDTV
                    </small>
                  </div>
                </div>
              </div>
              <div className="card-body custom-card-body-news">
                <p className="card-text for-custom-card-text-news card_content">

                  Lorem Ipsum is simply dummy text of the printing the printing super natural thing it is nice...
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-12 col-md-3">
            <div
              className="card custom-border-shadow-News"
              style={{ cursor: "pointer" }}
            >
              <div className="custom-img-parent-News">
                <img src={Doctor2} className="card-img-top" alt="..." />
                <div className="image-lineae-gradient"></div>
                <div className="img-absolute-News">
                  <div className="">
                    <img src={newsImg} alt="" className="src leftImg" />
                  </div>
                  <div className="newschanel">
                    {" "}
                    <small className="text-light custom-mobile-view-news">
                      NDTV
                    </small>
                  </div>
                </div>
              </div>
              <div className="card-body custom-card-body-news">
                <p className="card-text for-custom-card-text-news card_content">

                  Lorem Ipsum is simply dummy text of the printing the printing super natural thing it is nice...
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-12 col-md-3">
            <div
              className="card custom-border-shadow-News"
              style={{ cursor: "pointer" }}
            >
              <div className="custom-img-parent-News">
                <img src={Doctor2} className="card-img-top" alt="..." />
                <div className="image-lineae-gradient"></div>
                <div className="img-absolute-News">
                  <div className="">
                    <img src={newsImg} alt="" className="src leftImg" />
                  </div>
                  <div className="newschanel">
                    {" "}
                    <small className="text-light custom-mobile-view-news">
                      NDTV
                    </small>
                  </div>
                </div>
              </div>
              <div className="card-body custom-card-body-news">
                <p className="card-text for-custom-card-text-news card_content">

                  Lorem Ipsum is simply dummy text of the printing the printing super natural thing it is nice...
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="medi-card-home mt-3">
          <div className="row g-3">
            <div className="col-lg-3 col-md-3 px-2">
              <div className="medi-data-card">
                <div className="medi-card-img">
                  <img src={oilImage} alt="medi-card" />
                </div>
                <div className="medi-para">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry...{" "}
                  </p>
                </div>
                <div className="medi-btn">
                  <button>Get This</button>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 px-2">
              <div className="medi-data-card">
                <div className="medi-card-img">
                  <img src={moveImage} alt="medi-card" />
                </div>
                <div className="medi-para">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry...{" "}
                  </p>
                </div>
                <div className="medi-btn">
                  <button>Get This</button>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 px-2">
              <div className="medi-data-card">
                <div className="medi-card-img">
                  <img src={tabletImage} alt="medi-card" />
                </div>
                <div className="medi-para">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry...{" "}
                  </p>
                </div>
                <div className="medi-btn">
                  <button>Get This</button>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 px-2">
              <div className="medi-data-card">
                <div className="medi-card-img">
                  <img src={moveImage} alt="medi-card" />
                </div>
                <div className="medi-para">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry...{" "}
                  </p>
                </div>
                <div className="medi-btn">
                  <button>Get This</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="company-logo">
          <div className="row g-3">
            <div className="col-lg-3 col-md-3 col-6 px-2">
              <div className="company-img">
                <img src={Group3148} alt="company-logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 px-2">
              <div className="company-img">
                <img src={Group3149} alt="company-logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 px-2">
              <div className="company-img">
                <img src={Group3150} alt="company-logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 px-2">
              <div className="company-img">
                <img src={Group3151} alt="company-logo" />
              </div>
            </div>
          </div>
          <div className="row g-3 pdRow">
            <div className="col-lg-3 col-md-3 col-6 px-2">
              <div className="company-img">
                <img src={Group3152} alt="company-logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 px-2">
              <div className="company-img">
                <img src={Group3153} alt="company-logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 px-2">
              <div className="company-img">
                <img src={Group3154} alt="company-logo" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 px-2">
              <div className="company-img">
                <img src={Group3155} alt="company-logo" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Home;
