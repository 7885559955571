import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Doctor2 from "../../assets/Images/Group7262.png";
import "../../components/aboutus/AboutUs.css";
import { aboutUsData } from "../../Reducers/aboutSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { resetAllCardData } from "../../Reducers/allSubcategorySlice";
import { setMobileHeadingData } from "../../Reducers/mobileHeadingSlice";

function About() {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const { AboutUsData } = useSelector((state: RootState) => state.about);
    const [showMore, setShowMore] = useState<{ [key: number]: boolean }>({});

    useEffect(() => {
        dispatch(aboutUsData());
        dispatch(setMobileHeadingData("About"));
        dispatch(resetAllCardData());
    }, [dispatch]);

    const handleToggle = (index: number) => {
        setShowMore((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    return (
        <div className='container backgroundblur'>
            <div
                className="mainHeadAbout"
                style={{
                    textAlign: "center",
                    fontSize: "30px",
                    fontWeight: "700",
                    paddingTop: "10px",
                }}
            >
                <span>About us</span>
            </div>

            {[...Array(5)].map((_, i) => (
                <div key={i} className="container card mb-3 allCard">
                    <div className="row aboutrow">
                        <div className="col-md-3 col-3" style={{ padding: "0px" }}>
                            <img className='img-fluid aboutImg' src={Doctor2} alt="Doctor" />
                        </div>
                        <div className="col-md-9 col-9 text Web-about">
                            <span className='headText'>
                                <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply dummy text of the printing and industry.

                                <div
                                    className={`textContent ${showMore[i] ? 'expanded' : ''}`}
                                    style={{ textAlign: "justify" }}
                                >
                                    {AboutUsData.map((item, index) => (
                                        <div key={index} dangerouslySetInnerHTML={{ __html: item.about_us }} />
                                    ))}
                                </div>

                                <span className='read-more-btn' onClick={() => handleToggle(i)}>
                                    {showMore[i] ? 'Read Less' : 'Read More'}
                                </span>
                            </span>
                        </div>

                        <div className="col-md-9 col-9 text mob-about">
                            <span className='headMobText'>
                                <span style={{ color: "#247496" }}> Lorem Ipsum </span> is simply.

                                <div style={{ textAlign: "justify" }} className={`textMobContent ${showMore[i] ? 'expanded' : ''}`}>
                                    {AboutUsData.map((item, index) => (
                                        <div key={index} dangerouslySetInnerHTML={{ __html: item.about_us }} />
                                    ))}
                                </div>

                                <span className='read-more-btn' onClick={() => handleToggle(i)}>
                                    {showMore[i] ? 'Read Less' : 'Read More'}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default About;
