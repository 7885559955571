import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../components/signup/signup.css";
import vectorImage from "../../assets/Images/Vector (1).png";
import emailIcon from "../../assets/Images/user.png";
import passwordImage from "../../assets/Images/password.png";
import { useDispatch } from "react-redux";
import { login, userSignup2 } from "../../Reducers/authSlice";
import { useAppSelector } from "../../hook";
import Swal from "sweetalert2";
import { resetAllCardData } from "../../Reducers/allSubcategorySlice";
import { setMobileHeadingData } from "../../Reducers/mobileHeadingSlice";

function Signup() {
  const navigate=useNavigate()
  const dispatch = useDispatch<any>();
  const auth = useAppSelector((state: any) => state.auth);
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  // const [mobilenumber, setMobileNumber] = useState<string>("");

  // const handleFirstname = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setFirstname(e.target.value);
  // };
  // const handleLastname = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setLastName(e.target.value);
  // };
  const handleFirstname = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fullName = e.target.value;
    const lastSpaceIndex = fullName.lastIndexOf(' ');
    if (lastSpaceIndex !== -1) {
      const first = fullName.substring(0, lastSpaceIndex);
      const last = fullName.substring(lastSpaceIndex + 1);
      setFirstname(first);
      setLastName(last);
    } else {
      setFirstname(fullName);
      setLastName('');
    }
  };
  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };
  // const handleMobileNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setMobileNumber(e.target.value);
  // };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (password.length < 6) {
      Swal.fire({
        icon: "error",
        title: "Password Error",
        text: "Password must be at least 6 characters long",
      });
      return;
    }
    if (password !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Password Error",
        text: "Passwords do not match",
      });
      return;
    }
    if (!/[A-Z]/.test(password)) {
      Swal.fire({
        icon: "error",
        title: "Password Error",
        text: "Password must contain at least one uppercase letter",
      });
      return;
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      Swal.fire({
        icon: "error",
        title: "Password Error",
        text: "Password must contain at least one symbol (e.g., !@#$%^&*()...)",
      });
      return;
    }
    // if (mobilenumber.length !== 10) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Mobile Number Error",
    //     text: "Mobile number must be exactly 10 digits long",
    //   });
    //   return;
    // }

    const body = {
      firstname: firstname,
      email: email,
      password: password,
      confirm_password: confirmPassword,
      lastname: lastname,
      // mobile: mobilenumber,
    };

    dispatch(userSignup2(body))
      .unwrap()
      .then((res: any) => {
        if (res.success=true) {
          localStorage.setItem("userInfo", JSON.stringify(res.userDetails));
          dispatch(login());
          navigate("/");
          
          const redirectUrl = localStorage.getItem("redirectUrl");
          if (redirectUrl) {
            // navigate(redirectUrl);
            // setTimeout(()=>{
            //   localStorage.removeItem("redirectUrl");
            // },2000)
          } else {
            // navigate("/");
          }
          Swal.fire({
            icon: "success",
            title: "Registration successful!",
          });
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: "error",
          title: "Signup Failed",
          text: err.message,
        });
      });
  };
  useEffect(() => {

    dispatch(setMobileHeadingData("Sign-up"))

    dispatch(resetAllCardData());


}, [dispatch]);

  return (
    <>
      <div className="container backgroundblur">
        <form className="Form-field-parent float" onSubmit={handleSubmit}>
          <h2 className="sgnup-heading">Registration</h2>
          <hr />
          <div className="form-filed">
            <div className="form_filed-img">
              <img
                src={vectorImage}
                className="input1-icon user1-icon"
                alt="Person Icon"
              />
            </div>
            <input
              className="regi-input-signup"
              type="text"
              id="fullName"
              name="firstname"
              placeholder="FULL NAME"
              onChange={handleFirstname}
              required
            />
          </div>
          {/* <div className="form-filed">
            <div className="form_filed-img">
              <img
                src={vectorImage}
                className="input1-icon user1-icon"
                alt="Person Icon"
              />
            </div>
            <input
              className="regi-input-signup"
              type="text"
              id="lastName"
              name="lastname"
              placeholder="LAST NAME"
              onChange={handleLastname}
              required
            />
          </div> */}
          {/* <div className="form-filed">
            <div className="form_filed-img">
              <img
                src={vectorImage}
                className="input1-icon user1-icon"
                alt="Person Icon"
              />
            </div>
            <input
              className="regi-input-signup"
              type="text"
              id="mobile"
              name="mobilenumber"
              placeholder="MOBILE NUMBER"
              onChange={handleMobileNumber}
              required
            />
          </div> */}
          <div className="form-filed">
            <div className="form_filed-img">
              <img
                src={emailIcon}
                className="input1-icon user1-icon"
                alt="Person Icon"
              />
            </div>
            <input
              className="regi-input-signup"
              type="email"
              id="email"
              name="email"
              placeholder="EMAIL ID"
              onChange={handleEmail}
              required
            />
          </div>
          <div className="form-filed">
            <div className="form_filed-img">
              <img
                src={passwordImage}
                className="input1-icon user1-icon"
                alt="Person Icon"
              />
            </div>
            <input
              className="regi-input-signup"
              type="password"
              id="password"
              name="password"
              placeholder="PASSWORD"
              onChange={handlePassword}
              required
            />
          </div>
          <div className="form-filed">
            <div className="form_filed-img">
              <img
                src={passwordImage}
                className="input1-icon user1-icon"
                alt="Person Icon"
              />
            </div>
            <input
              className="regi-input-signup"
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="CONFIRM YOUR PASSWORD"
              onChange={handleConfirmPassword}
              required
            />
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="agreeTerms"
              name="agreeTerms"
              className="checkbox-input"
              required
            />
            <label htmlFor="agreeTerms" className="checkbox-label item">
              I agree to the{" "}
              <Link to="/termsconditions" className="item tcp">
                Terms &amp; Conditions
              </Link>{" "}
              and{" "}
              <Link to="/privacypolicy" className="item tcp">
                Privacy Policy
              </Link>
            </label>
          </div>
          <div className="sgn-btn">
            <button type="submit">SIGN UP</button>
          </div>
          <div className="user1">
            <Link to="/login" className="Signup_link">
              EXISTING USER?
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default Signup;
